import React from "react"
import { Link } from 'gatsby'
import ButtonCTA from '../components/buttonCTA'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error text-center py-40">
      <h1>Error : 404</h1>
      <p>There's nothing here. <Link to="/" className="blue">Return Home</Link></p>
    </div>
    <ButtonCTA />
  </Layout>
)

export default NotFoundPage
